var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageHeaderDisplay', {
    attrs: {
      "title": "Dashboard",
      "sub-title": "Detailed graphs and tables for your portfolios and assets",
      "category": "Analytics",
      "category-logo": "fa-file-chart-column",
      "category-route": {
        name: _vm.$route.name
      }
    }
  }), _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "bg-lighter rounded-md px-4 py-3 mb-4"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "input-group"
  }, [_c('FormItem', {
    staticStyle: {
      "max-width": "40px"
    },
    attrs: {
      "id": "filterType",
      "options": [{
        value: 'asset',
        label: 'Asset'
      }, {
        value: 'portfolio',
        label: 'Portfolio'
      }],
      "type": "select",
      "alt": ""
    },
    on: {
      "input": _vm.onChangeType
    },
    model: {
      value: _vm.options.type,
      callback: function callback($$v) {
        _vm.$set(_vm.options, "type", $$v);
      },
      expression: "options.type"
    }
  }), _c('FormItem', {
    staticStyle: {
      "max-width": "20rem"
    },
    attrs: {
      "id": "selectedId",
      "options": _vm.sortedOptions,
      "type": "select2",
      "placeholder": "Select Item",
      "config": {
        allowClear: true,
        allowSearch: true
      }
    },
    on: {
      "input": _vm.onSelectItem
    },
    model: {
      value: _vm.options.selectedId,
      callback: function callback($$v) {
        _vm.$set(_vm.options, "selectedId", $$v);
      },
      expression: "options.selectedId"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center mr-3 border-right pr-3"
  }, [_c('div', {
    staticClass: "form-static-text pr-3"
  }, [_vm._v("Date Range")]), _c('FormItem', {
    staticClass: "mb-0",
    attrs: {
      "id": "dateRange",
      "type": "dateRangePicker",
      "disabled": _vm.loadingAction.getConsumptions
    },
    on: {
      "input": _vm.onDateRangeUpdate
    },
    model: {
      value: _vm.options.dateRange,
      callback: function callback($$v) {
        _vm.$set(_vm.options, "dateRange", $$v);
      },
      expression: "options.dateRange"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center mr-3 border-right pr-3"
  }, [_c('div', {
    staticClass: "form-static-text pr-3"
  }, [_vm._v("Compared To")]), _c('FormItem', {
    staticClass: "mb-0",
    staticStyle: {
      "width": "300px"
    },
    attrs: {
      "id": "graph-compare-period",
      "type": "select",
      "options": _vm.comparePeriods
    },
    on: {
      "input": _vm.onComparePeriodUpdate
    },
    model: {
      value: _vm.options.comparePeriod,
      callback: function callback($$v) {
        _vm.$set(_vm.options, "comparePeriod", $$v);
      },
      expression: "options.comparePeriod"
    }
  })], 1), _vm.$route.path !== '/analytics/costs' ? _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "form-static-text pr-3"
  }, [_vm._v("Source")]), _c('FormItem', {
    staticClass: "mb-0",
    attrs: {
      "id": "source",
      "type": "select",
      "options": [{
        label: 'Combined',
        value: 'combined'
      }, {
        label: 'Invoice',
        value: 'invoice'
      }, {
        label: 'Readings',
        value: 'reading'
      }, {
        label: 'Custom Consumption',
        value: 'custom'
      }, {
        label: 'HH Data',
        value: 'hh'
      }]
    },
    on: {
      "input": _vm.onSourceUpdate
    },
    model: {
      value: _vm.options.source,
      callback: function callback($$v) {
        _vm.$set(_vm.options, "source", $$v);
      },
      expression: "options.source"
    }
  })], 1) : _vm._e()])]), _vm.options.selectedId ? _c('router-view') : _c('div', [_c('div', {
    staticClass: "mt-5 text-center alert alert-warning"
  }, [_vm._v("Select a site above to view analytics.")])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }